<template>
    <div class="ztgjbhPart swzsScreen">
        <div class="actionPart flex-between">
            <el-select
                v-model="yearsNow"
                filterable
                @change="getDetailArea()"
                placeholder="请选择对比年份"
                clearable
            >
                <el-option
                    v-for="(item, index) in yearsList"
                    :key="index"
                    :label="item.time"
                    :value="item.ID"
                >
                </el-option>
            </el-select>
            <el-button type="primary" class="backBtn" @click="toList"
                >列表视图</el-button
            >
        </div>
        <Mymap :myMap.sync="myMap" :mapData="mapData" mapSize="image" />
        <div
            id="select-popup"
            class="clusterPart"
            v-show="popupTitle.list.length"
        >
            <div class="title">
                <i
                    class="closeBtn pointer el-icon-close"
                    @click="closeCluster()"
                ></i>
            </div>
            <div class="clusterCtn scrollbar-translucent">
                <div
                    v-for="(item, index) in popupTitle.list"
                    :key="index"
                    class="ycdtItem pointer flex-between-y-center"
                >
                    <div
                        style="width: 170px"
                        class="pointer ellipsis"
                        :title="item.name"
                    >
                        {{ item.name }}
                    </div>

                    <div
                        style="width: 170px"
                        class="pointer ellipsis"
                        :title="item.name"
                    >
                        {{ item.value }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    components: {},
    data() {
        return {
            myMap: null,
            mapData: {
                zoom: 12.6,
                dxtState: true,
                areaState: true,
                mapTypeBtn: false,
            },
            yearsList: [],
            yearsNow: "",
            areaGroup: null,
            areaGroupName: null,
            // 查看详情
            jhPopup: null,
            popupTitle: {
                list: [],
            },
        };
    },
    watch: {},
    mounted() {
        this.areaGroup = window.L.layerGroup().addTo(this.myMap);
        this.areaGroupName = window.L.layerGroup().addTo(this.myMap);
        this.getMainData();
        this.myMap.on("zoomend", (e) => {
            // console.log("放大等级inner:", e.target._zoom, this.layerArea);
            // let level = e.target._zoom;
            // if (level >= 14.5) {
            //     this.drawAreaName(this.areaGroupName, true);
            // } else {
            //     this.drawAreaName(this.areaGroupName, false);
            // }
        });

        let popup = document.getElementById("select-popup");
        this.jhPopup = L.popup({
            closeButton: false,
            offset: [0, 0],
            minWidth: 200,
            className: "select-popup",
        })
            .setContent(popup)
            .setLatLng([0, 0]);
    },
    methods: {
        ...mapActions(["getMonitorDataList"]),
        toList() {
            this.$router.push({
                name: "monitoringTable",
                params: {
                    ItemID: "402",
                    MC: "总体格局变化记录及记录图",
                },
            });
        },
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        async getMainData() {
            let result = await this.getMonitorDataList({
                userid: localStorage.userId,
                itemId: 402,
                authority: false,
                keyWord: null,
                // strwhere: ["ZTGJSFBH: 1"],
                strwhere: null,
                sort: null,
                sortorder: null,
                start: null,
                end: null,
                pageIndex: null,
                pageSize: null,
            });
            result.ResultValue.gridData.map((item) => {
                item.time = `${item.JZT} - ${item.DBT}`;
            });
            this.yearsList = result.ResultValue.gridData;
            this.drawArea(this.areaGroup, this.yearsList);
        },
        // 画图斑
        drawArea(group, list) {
            let icon = L.icon({
                iconUrl: require("@image/bigScreen/marker/yellow.png"),
                iconAnchor: [7, 7],
            });
            list.map((item) => {
                L.geoJSON(JSON.parse(item.GEOJSON), {
                    pointToLayer: function (geoJsonPoint, latlng) {
                        return L.marker(latlng, {
                            icon: icon,
                        });
                    },
                    onEachFeature: (feature, layer) => {
                        // layer.setStyle({
                        //     fillColor: "#A2CD5A",
                        //     color: "#A2CD5A",
                        //     fillOpacity: "0.3",
                        // });
                        layer.on("click", () => {
                            let lngMarker = 0;
                            let latMarker = 0;
                            let markerList = [];
                            let obj = JSON.parse(item.GEOJSON).geometry;
                            if (obj.type == "Point") {
                                markerList = [obj.coordinates];
                            } else {
                                markerList = obj.coordinates[0];
                            }

                            markerList.map((item) => {
                                lngMarker += item[0];
                                latMarker += item[1];
                            });
                            lngMarker = lngMarker / markerList.length;
                            latMarker = latMarker / markerList.length;

                            this.popupTitle.list = [];
                            let obj1 = {
                                name: "情况说明",
                                value: item.QKSM,
                            };
                            this.popupTitle.list.push(obj1);
                            obj1 = {
                                name: "评估",
                                value: item.PG_DESC,
                            };
                            this.popupTitle.list.push(obj1);
                            this.jhPopup
                                .setLatLng([latMarker, lngMarker])
                                .openOn(this.myMap);
                        });
                    },
                    style: function () {
                        return {
                            renderer: L.svg(),
                        };
                    },
                }).addTo(group);
            });
        },
        // 画面
        drawAreaName(group, state) {
            this.clearLayersGroup(group);
            this.yearsList.map((item) => {
                let lngMarker = 0;
                let latMarker = 0;
                let markerList = [];
                let obj = JSON.parse(item.GEOJSON).geometry;
                if (obj.type == "Point") {
                    markerList = [[obj.coordinates[1], obj.coordinates[0]]];
                } else {
                    markerList = obj.coordinates[0];
                }

                markerList.map((item) => {
                    lngMarker += item[0];
                    latMarker += item[1];
                });
                lngMarker = lngMarker / markerList.length;
                latMarker = latMarker / markerList.length;

                let icon = window.L.divIcon({
                    html: `<div style="min-width:100px;color:#fff;white-space:nowrap;font-size:18px;font-weight:600;">${item.TBNAME}</div>`,
                    className: "",
                });
                if (state) {
                    window.L.marker([latMarker, lngMarker], {
                        icon: icon,
                    }).addTo(group);
                }
            });
        },
        // 飞到面区
        getDetailArea() {
            let json = JSON.parse(
                this.common.aryListFindOne(this.yearsList, "ID", this.yearsNow)
                    .GEOJSON
            ).geometry;
            let list = [];
            if (json.type == "Point") {
                list = [[json.coordinates[1], json.coordinates[0]]];
            } else {
                json.coordinates[0].map((item) => {
                    list.push([item[1], item[0]]);
                });
            }
            this.myMap.flyToBounds(list);
        },
        // 关闭聚合
        closeCluster() {
            this.jhPopup.removeFrom(this.myMap);
        },
    },
};
</script>

<style lang="scss" scoped>
.ztgjbhPart {
    position: relative;
    .actionPart {
        position: absolute;
        z-index: 999;
        width: 100%;
        padding: 20px;
        .backBtn {
            position: relative;
            top: 50px;
        }
    }
    .clusterPart {
        width: 201px;
        .closeBtn {
            width: 20px;
            height: 20px;
            font-size: 18px;
            color: #fff;
        }
        .title {
            text-align: right;
        }
        .clusterCtn {
            margin-top: 10px;
            max-height: 121px;
            width: 100%;
            .ycdtItem {
                color: rgba(126, 190, 235, 1);
                width: calc(100% - 3px);
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                border-bottom: 1px solid rgba(186, 232, 240, 0.5);
                &:hover {
                    color: #fff;
                }
            }
        }
    }
}
</style>
